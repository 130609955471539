import React from 'react'

const GoogleMap = () => {
    return (
        <div className="google-map-area section text-center section-padding-bottom">
            <div className="container">
                <div className="contact-map-area" data-aos="fade-up">
                    <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1885.2297729481736!2d72.83649665805522!3d19.08748553151401!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9a5da694023%3A0xd80eaae9e3326c67!2sB-1%2C%20Khira%20Nagar%2C%20Santacruz%20West%2C%20Mumbai%2C%20Maharashtra%20400054!5e0!3m2!1sen!2sin!4v1677419453393!5m2!1sen!2sin"  aria-hidden="false"></iframe>
                  
                </div>
            </div>
        </div>
    )
}

export default GoogleMap;
