import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const Chicken = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  return (
    <React.Fragment>
      <SEO title="Shekhawati || Chicken" />
      <Header />
      <Breadcrumb image="images/bg/a.jpg" title="" content="Home" contentTwo="Chicken" />
      {/* four */}
      <div className="section section-padding-top about-section-padding-bottom-200">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="about-content-area mt-0 mb-md-10 mb-10">
                {/*  <SectionTitleTwo
                subTitle="Web design and digital marketing"
                title="We think strategy, UX design, and web development"
              /> */}

                {/* <div className="row row-cols-sm-2 row-cols-auto mb-n6"> */}
                {/* <div className="col mb-6">
                  <div className="about-funfact">
                    <div className="number">
                      <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
                        <CountUp end={didViewCountUp ? 110 : 0} />
                      </VisibilitySensor>
                      +
                    </div>
                    <h6 className="text">Happy Clients</h6>
                    <p>We help our clients increase profits by increasing their visibility online.</p>
                  </div>
                </div> */}

                <div className="about-content-area">
                  <SectionTitleTwo
                    /* subTitle="Our solutions begin with brand research" */
                    title="Chicken Unleashed: Uncovering the Magic of Raw Chicken and Chicken Products"
                  />

                  <p>
                    The quality of chicken is an important consideration for consumers who are looking for a safe,
                    nutritious, and tasty product.Different breeds of chickens have different meat characteristics, such
                    as texture and flavor. The breed of thechicken can affect the quality of the meat. The feed and
                    environment in which the chicken is raised can also affect the quality of the meat. Chickens that
                    are raised in a healthy and natural environment and fed a balanced diet are likely to produce better
                    quality meat. The processing and handling of the chicken can affect its quality. Proper handling and
                    processing 12 techniques, such as maintaining a clean environment, using appropriate equipment and
                    tools, and ensuring appropriate storage and transportation, can help to maintain the quality of the
                    meat.
                  </p>
                </div>

                {/* <div className="col mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                onChange={onVisibilityChange}
                                                offset={{ top: 10 }}
                                                delayedCall
                                                >
                                                <CountUp end={didViewCountUp ? 130 : 0} />
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">Completed projects</h6>
                                        <p>We help our clients increase profits by increasing their visibility online.</p>
                                    </div>
                                </div> */}
                {/*  </div> */}
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/chicken/chicken1.jpg"} alt="" width="300" height="370" />
                  </Tilt>
                </div>
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/chicken/chicken2.jpg"} alt="" width="370" height="320" />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* five */}

      <div className="section section-padding-top section-padding-bottom-180">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
              <div className="about-image-area">
                <div className="about-image">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/chicken/chicken4.jpg"} alt="" width="370" height="320" />
                  </Tilt>
                </div>
                <div className="about-image">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/chicken/chicken3.jpg"} alt="" width="370" height="370" />
                  </Tilt>
                </div>

                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" />
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="about-content-area">
                <SectionTitleTwo
                  /*  subTitle="Our solutions begin with brand research" */
                  title="Clucking Delicious: Your One-Stop Guide to All Things Chicken and Chicken Products!"
                />

                <p>
                  Fresh chicken and chicken products are versatile, affordable, and delicious ingredients that can be
                  used to create a wide range of dishes for any meal. Chicken is a great source of lean protein,
                  essential vitamins and minerals, and amino acids that help support a healthy diet and lifestyle. From
                  succulent chicken breasts and tender thighs to flavorful chicken sausages and nuggets, there are many
                  options to choose from for cooking with chicken. With proper handling, storage, and preparation, fresh
                  chicken and chicken products can be transformed into a variety of mouthwatering dishes, from classic
                  roasted chicken and fried chicken wings to chicken tacos and stir-fries.{/*  Whether you're a seasoned
                  cook or a novice in the kitchen, there are endless possibilities to explore and enjoy the rich and
                  flavorful world of fresh chicken and chicken products */}
                </p>

                {/*  <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/"}>Get Started</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Chicken;
