import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import FooterData from "../../data/Footer/footerItem.json";
import FooterLinkItem from "../../components/Footer/FooterLinkItem.jsx";

const Footer = () => {
  return (
    <div className="footer-section section footer-bg-color">
      <div className="container">
        <div className="row mb-lg-14 mb-md-10 mb-6">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
            <div className="footer-widget">
              <div className="footer-logo">
                <Logo image={`${process.env.PUBLIC_URL}/images/logo/logo3.png`} />
              </div>
              <div className="footer-widget-content">
                <div className="content">
                  <p>
                  <a href="tel:+919987810786">+91 9987810786</a> , <a href="tel:+918850446676">+91 8850446676</a>
                   <br/><a href="tel:+919610188001">+91 9610188001</a> , <a href="tel:+919414324809">+91 9414324809</a>
                  </p>
                  <p>
                    <a href="mailto:support@shekhawatienterprises.in ">support@shekhawatienterprises.in </a>
                  </p>
                </div>
                <div className="footer-social-inline">
                  <a href="https://twitter.com/Shekhawatient?t=Go42kQj5tdsnfSMjcLwdBg&s=09" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter-square"></i>
                  </a>
                  <a href="https://www.facebook.com/profile.php?id=100090717849103&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a href="https://instagram.com/shekhawati.enterprises?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a href="https://api.whatsapp.com/send?phone=9930272603" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {FooterData &&
            FooterData.map((single, key) => {
              return (
                <div key={key} className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 mb-6">
                  <FooterLinkItem data={single} key={key} />
                </div>
              );
            })}
        </div>

        <div className="row">
          <div className="col">
            <p className="copyright">
              &copy; 2023 <strong>Shekhawati Enterprises</strong> Made with <i className="fas fa-heart text-danger"></i>{" "}
              in <strong>India</strong>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
