import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const Clientele = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  return (
    <React.Fragment>
      <SEO title="Shekhawati || Clientele" />
      <Header />
      <Breadcrumb image="images/bg/a.jpg" title="" content="Home" contentTwo="Clientele" />

      {/* five */}

      <div className="section section-padding-top section-padding-bottom-180">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12 " data-aos="fade-up" data-aos-delay="300">
              <p>
                Our customers are the backbone of our company and their importance cannot be overstated. They provide us
                with the revenue we need to sustain and grow our business, but their value extends far beyond just their
                monetary contributions.
              </p>
              <p>
                Firstly, our customers are the ones who use our products or services, and their feedback and
                satisfaction are crucial to the success of our business. By listening to their needs and responding to
                their feedback, we can improve our offerings, provide better customer service, and build brand loyalty.
              </p>
              <p>
                Secondly, our customers also act as brand ambassadors for our company. When they are satisfied with our
                products or services, they are likely to recommend them to others, which can lead to new customers and
                increased revenue.
              </p>
              <p>
                Lastly, our customers can also help us innovate and develop new products or services. By understanding
                their needs and pain points, we can identify new opportunities and create solutions that meet their
                unique needs. In summary, our customers are essential to our companies success and growth, and we must
                prioritize their needs and satisfaction to build a strong and loyal customer base.
              </p>

              <div className="mt-20">
                
                <h2>Lists of some of our valuable client</h2>
                <ul>
                  <li>Major General of ASC.HQ SWC,Rajasthan</li>
                  <li>Brigadier ASC 1 Core,Uttar Pradesh</li>
                  <li>National Zoological Park,New Delhi</li>
                  <li>Indo-Tibetian Border Police force (ITBP)</li>
                  <li>Brigadier ASC HQ 10 Corps,Punjab</li>
                  <li>Brigadier ASC ,Maharashtra & Goa</li>
                  <li>Sports Authority of India</li>
                  <li>Commanding Officer ASC,Bikaner & Jaipur,Rajasthan</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Clientele;
