import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import {Link} from "react-router-dom";

const CallToActionTwo = () => {
    return (
        <div className="cta-section section section-padding-250">
            <div className="container text-center icon-up-down-animation">
                <SectionTitle
                    headingOption="fz-34"
                    title="Let's talk about your project and see how we can work together"
                    subTitle="Shekhawati Enterprises is your trusted supplier of fresh and high-quality food products since 2003. Contact us today by phone, email, or visit us in person at our address to learn more."
                />
                <Link className="btn btn-primary btn-hover-secondary" to={process.env.PUBLIC_URL + "/contact"} data-aos="fade-up" data-aos-delay="300">Contact Us</Link>

                <div className="shape shape-1">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/svg/linea/chicken.svg"} alt="" />
                    </span>
                </div>
                <div className="shape shape-2">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/svg/linea/sheep.svg"} alt="" />
                    </span>
                </div>
                <div className="shape shape-3">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/svg/linea/eggs-tray.svg"} alt="" />
                    </span>
                </div>
                <div className="shape shape-4">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/svg/linea/berries.svg"} alt="" />
                    </span>
                </div>
                <div className="shape shape-5">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/svg/linea/sheep.svg"} alt="" />
                    </span>
                </div>
                <div className="shape shape-6">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/svg/linea/chicken.svg"} alt="" />
                    </span>
                </div>
                <div className="shape shape-7">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/svg/linea/vegetable-bucket.svg"} alt="" />
                    </span>
                </div>
                <div className="shape shape-8">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/svg/linea/eggs-tray.svg"} alt="" />
                    </span>
                </div>
                <div className="shape shape-9">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/svg/linea/cooked-fish.svg"} alt="" />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default CallToActionTwo;
