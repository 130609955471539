import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import { Link } from "react-router-dom";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const AboutFive = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div className="section section-padding-top section-padding-bottom-180">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
            <div className="about-image-area">
              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img src={process.env.PUBLIC_URL + "images/about/truck.jpeg"} alt="" width="420" height="320" />
                </Tilt>
              </div>
              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img src={process.env.PUBLIC_URL + "images/about/test.jpg"} alt="" />
                </Tilt>
              </div>

              <div className="shape shape-1" id="scene" ref={sceneEl}>
                <span data-depth="1">
                  <img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" />
                </span>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
            <div className="about-content-area">
              <SectionTitleTwo
                /*  subTitle="Our solutions begin with brand research" */
                title="Supplying Raw Meat, Eggs, Poultry, Seafood, Fresh Vegetables, and Fruits Since 2003"
              />

              <p>
                At Shekhawati Enterprises, we understand the importance of quality and safety in the food industry. That
                is why we have implemented rigorous quality control measures to ensure that our products meet the
                highest standards of freshness, hygiene, and nutritional value. Our team of experienced professionals
                works tirelessly to maintain the quality of our products and ensure that they reach our customers in the
                best possible condition. We are committed to providing our customers with a wide range of high-quality
                products at competitive prices. Whether you are looking for fresh meat, seafood, vegetables, or fruits,
                we have everything you need to create delicious and healthy meals for yourself and your family. Thank
                you for choosing Shekhawati Enterprises as your food supplier. We look forward to serving you and
                helping you achieve your culinary goals.
              </p>

              {/*  <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/"}>Get Started</Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
