import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const Eggs = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  return (
    <React.Fragment>
      <SEO title="Shekhawati || Eggs" />
      <Header />
      <Breadcrumb image="images/bg/a.jpg" title="" content="Home" contentTwo="Eggs" />
      {/* four */}
      <div className="section section-padding-top about-section-padding-bottom-200">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="about-content-area mt-0 mb-md-10 mb-10">
                {/*  <SectionTitleTwo
                subTitle="Web design and digital marketing"
                title="We think strategy, UX design, and web development"
              /> */}

                {/* <div className="row row-cols-sm-2 row-cols-auto mb-n6"> */}
                {/* <div className="col mb-6">
                  <div className="about-funfact">
                    <div className="number">
                      <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
                        <CountUp end={didViewCountUp ? 110 : 0} />
                      </VisibilitySensor>
                      +
                    </div>
                    <h6 className="text">Happy Clients</h6>
                    <p>We help our clients increase profits by increasing their visibility online.</p>
                  </div>
                </div> */}

                <div className="about-content-area">
                  <SectionTitleTwo
                    /* subTitle="Our solutions begin with brand research" */
                    title="Crack into the World of Eggs: Our Company Offers the Best Selection of Fresh and High-Quality Eggs"
                  />

                  <p>
                    Eggs are a versatile and nutritious ingredient that can be enjoyed in countless ways. They are a
                    great source of protein, vitamins, and minerals, making them a staple in many healthy diets. From
                    classic breakfast dishes like scrambled eggs and omelets to baked goods and savory entrees, eggs are
                    a key ingredient in many recipes. Whether you prefer them soft-boiled, hard-boiled, poached, or
                    fried, there are many ways to enjoy the delicious and nutritious benefits of eggs. At our company,
                    we offer a wide range of fresh and high-quality eggs that are responsibly sourced and rigorously
                    tested to ensure their quality and freshness.
                  </p>
                </div>

                {/* <div className="col mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                onChange={onVisibilityChange}
                                                offset={{ top: 10 }}
                                                delayedCall
                                                >
                                                <CountUp end={didViewCountUp ? 130 : 0} />
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">Completed projects</h6>
                                        <p>We help our clients increase profits by increasing their visibility online.</p>
                                    </div>
                                </div> */}
                {/*  </div> */}
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/eggs/eggs1.jpg"} alt="" width="370" height="370" />
                  </Tilt>
                </div>
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/eggs/eggs2.jpg"} alt="" width="300" height="350" />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* five */}

      <div className="section section-padding-top section-padding-bottom-180">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
              <div className="about-image-area">
                <div className="about-image">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/eggs/eggs3.jpg"} alt="" width="320" height="320" />
                  </Tilt>
                </div>
                <div className="about-image">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/eggs/eggs4.jpg"} alt="" width="420" height="320" />
                  </Tilt>
                </div>

                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" />
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="about-content-area">
                <SectionTitleTwo
                  /*  subTitle="Our solutions begin with brand research" */
                  title="Egg-ceptional Quality and Taste: Discover the Versatility and Nutritional Benefits of Our Responsibly Sourced Eggs and Egg Products"
                />

                <p>
                  Eggs are a highly nutritious and versatile ingredient that can be used in a variety of recipes. They
                  are an excellent source of protein, vitamins, and minerals that promote a healthy diet and lifestyle.
                  From classic breakfast dishes like eggs Benedict and frittatas to cakes, cookies, and other baked
                  goods, eggs are a key ingredient in many recipes.Our eggs undergo rigorous testing to ensure that they meet our quality standards and provide our customers with
                  the best possible experience.
                </p>

                {/*  <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/"}>Get Started</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Eggs;
