import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const Vegetable = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  return (
    <React.Fragment>
      <SEO title="Shekhawati || Vegetable" />
      <Header />
      <Breadcrumb image="images/bg/a.jpg" title="" content="Home" contentTwo="Vegetable" />
      {/* four */}
      <div className="section section-padding-top about-section-padding-bottom-200">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="about-content-area mt-0 mb-md-10 mb-10">
                {/*  <SectionTitleTwo
                subTitle="Web design and digital marketing"
                title="We think strategy, UX design, and web development"
              /> */}

                {/* <div className="row row-cols-sm-2 row-cols-auto mb-n6"> */}
                {/* <div className="col mb-6">
                  <div className="about-funfact">
                    <div className="number">
                      <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
                        <CountUp end={didViewCountUp ? 110 : 0} />
                      </VisibilitySensor>
                      +
                    </div>
                    <h6 className="text">Happy Clients</h6>
                    <p>We help our clients increase profits by increasing their visibility online.</p>
                  </div>
                </div> */}

                <div className="about-content-area">
                  <SectionTitleTwo
                    /* subTitle="Our solutions begin with brand research" */
                    title="Experience the Rich Flavors and Nutritional Benefits of Fresh Vegetables: Discover Our Wide Range of Seasonal and Locally Sourced Produce to Elevate Your Experience"
                  />

                  <p>
                    Experience the Bounty of Nature with Our Companies Wide Selection of Nutrient-Rich and Fresh
                    Vegetables. From Leafy Greens to Root Vegetables, Our Farm-Fresh Produce is Perfect for All Your
                    Culinary Adventures.
                  </p>
                </div>

                {/* <div className="col mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                onChange={onVisibilityChange}
                                                offset={{ top: 10 }}
                                                delayedCall
                                                >
                                                <CountUp end={didViewCountUp ? 130 : 0} />
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">Completed projects</h6>
                                        <p>We help our clients increase profits by increasing their visibility online.</p>
                                    </div>
                                </div> */}
                {/*  </div> */}
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={process.env.PUBLIC_URL + "images/vegetable/vegetable1.jpg"}
                      alt=""
                      width="330"
                      height="370"
                    />
                  </Tilt>
                </div>
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={process.env.PUBLIC_URL + "images/vegetable/vegetable2.jpg"}
                      alt=""
                      width="350"
                      height="370"
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* five */}

      <div className="section section-padding-top section-padding-bottom-180">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
              <div className="about-image-area">
                <div className="about-image">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={process.env.PUBLIC_URL + "images/vegetable/vegetable3.jpg"}
                      alt=""
                      width="350"
                      height="370"
                    />
                  </Tilt>
                </div>
                <div className="about-image">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={process.env.PUBLIC_URL + "images/vegetable/vegetable4.jpg"}
                      alt=""
                      width="420"
                      height="320"
                    />
                  </Tilt>
                </div>

                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" />
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="about-content-area">
                <SectionTitleTwo
                  /*  subTitle="Our solutions begin with brand research" */
                  title="Fresh and Wholesome Vegetables for a Healthy Lifestyle: Our Company's Wide Range of Seasonal and Locally Sourced Produce is Perfect for Every occasions"
                />

                <p>
                  Experience the Best of Natures Bounty with Our Companies Fresh Vegetables. Picked at the Peak of
                  Ripeness and Delivered to You at the Peak of Freshness, Our Produce is the Perfect Addition to Any
                  Meal. From Classic Carrots to Exotic Eggplant, We Have a Wide Variety of Vegetables to Suit Every
                  Palate.
                </p>

                {/*  <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/"}>Get Started</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Vegetable;
