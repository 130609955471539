import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const Fish = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  return (
    <React.Fragment>
      <SEO title="Shekhawati || Fish" />
      <Header />
      <Breadcrumb image="images/bg/a.jpg" title="" content="Home" contentTwo="Fish" />
      {/* four */}
      <div className="section section-padding-top about-section-padding-bottom-200">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="about-content-area mt-0 mb-md-10 mb-10">
                {/*  <SectionTitleTwo
                subTitle="Web design and digital marketing"
                title="We think strategy, UX design, and web development"
              /> */}

                {/* <div className="row row-cols-sm-2 row-cols-auto mb-n6"> */}
                {/* <div className="col mb-6">
                  <div className="about-funfact">
                    <div className="number">
                      <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
                        <CountUp end={didViewCountUp ? 110 : 0} />
                      </VisibilitySensor>
                      +
                    </div>
                    <h6 className="text">Happy Clients</h6>
                    <p>We help our clients increase profits by increasing their visibility online.</p>
                  </div>
                </div> */}

                <div className="about-content-area">
                  <SectionTitleTwo
                    /* subTitle="Our solutions begin with brand research" */
                    title="Fresh Catch: Dive into the Delicious World of Fish and Seafood"
                  />

                  <p>
                    Fresh fish and seafood are delicious, healthy, and versatile ingredients that can add a unique touch
                    to any meal. They are a great source of lean protein, omega-3 fatty acids, and other essential
                    nutrients that promote heart health, brain function, and overall well-being. From succulent shrimp
                    and tender scallops to meaty tuna and flavorful salmon, there is a vast range of fish and seafood
                    options to suit every palate and cooking preference.
                  </p>
                </div>

                {/* <div className="col mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                onChange={onVisibilityChange}
                                                offset={{ top: 10 }}
                                                delayedCall
                                                >
                                                <CountUp end={didViewCountUp ? 130 : 0} />
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">Completed projects</h6>
                                        <p>We help our clients increase profits by increasing their visibility online.</p>
                                    </div>
                                </div> */}
                {/*  </div> */}
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/fish/fish1.jpg"} alt="" width="400" height="370" />
                  </Tilt>
                </div>
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/fish/fish2.jpg"} alt="" width="370" height="370" />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* five */}

      <div className="section section-padding-top section-padding-bottom-180">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
              <div className="about-image-area">
                <div className="about-image">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/fish/fish4.jpg"} alt="" width="370" height="370" />
                  </Tilt>
                </div>
                <div className="about-image">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img src={process.env.PUBLIC_URL + "images/fish/fish3.jpg"} alt="" width="370" height="370" />
                  </Tilt>
                </div>

                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" />
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="about-content-area">
                <SectionTitleTwo
                  /*  subTitle="Our solutions begin with brand research" */
                  title="Hooked on Seafood: A Culinary Adventure with Fresh Fish and Seafood"
                />

                <p>
                  Fresh fish and seafood are healthy, delicious, and versatile ingredients that can elevate any dish.
                  From succulent shrimp and tender scallops to meaty tuna and flavorful salmon, there is a wide range of
                  fish and seafood options available to suit every taste and cooking style. With the right handling and
                  preparation, fresh fish and seafood can be transformed into an array of delicious dishes, from classic
                  fish and chips to exotic ceviche. Whether you are a seasoned chef or a curious cook, there is no limit
                  to the culinary possibilities of fresh fish and seafood.
                </p>

                {/*  <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/"}>Get Started</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Fish;
